export const decimalTwoFormat = (value) => {
  if (value == null || value === " ") return "0.00";
  const valueLocale = thousandsSeparator(value.toString());
  const [fullNumber, digit] = valueLocale.split(".");
  if (digit) {
    return fullNumber + "." + digit.substring(0, 2).padEnd(2, "0");
  }
  return fullNumber + ".00";
};

export const decimalFourFormat = (value) => {
  if (value == null || value === "") return "0.0000";
  const valueLocale = thousandsSeparator(value.toString());
  const [fullNumber, digit] = valueLocale.split(".");
  if (digit) {
    return fullNumber + "." + digit.substring(0, 4).padEnd(4, "0");
  }
  return fullNumber + ".0000";
};

export const thousandsSeparator = (value) => {
  if (typeof value === "number" || !isNaN(value)) {
    const parsedValue = parseFloat(value);
    const formattedValue = parsedValue
      .toLocaleString("th-TH", { minimumFractionDigits: 2, maximumFractionDigits: 20 })
      .replace(/\.0+$/, "");

    return formattedValue.includes(".") ? formattedValue : `${formattedValue}.00`;
  }
  return value.toString();
};
