import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  Divider,
  TextField,
  Button,
  Link as MLink,
  Alert,
} from "@mui/material";
import { useAPI } from "../services/happypay-api";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import logo from "../assets/logo/logo.svg";
import SingleBoxLayout from "../layout/SingleBoxLayout";
import { forgotPasswordState } from "../states/forgot-password";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [errorMessage, setErrorMessage] = useRecoilState(
    forgotPasswordState.errorMessage
  );
  const [isSubmitted, setIsSubmitted] = useRecoilState(
    forgotPasswordState.isSubmitted
  );
  const [email, setEmail] = useState('')
  const [error, setError] = useState({});
  const api = useAPI();

  const { handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      username: ""
    }
  });

  const validateForm = () => {
    const emailError = {};

    if (!email) {
      emailError.email = "Email should not be empty.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError.email = "Email must be an email";
    }

    setError(emailError);

    return Object.keys(emailError).length === 0;
  };

  const onSubmit = (values) => {
    if (!validateForm()) return;

    api
      .recoveryPassword(email)
      .then(async (data) => {
        const { statusCode, message } = data;
        if (!statusCode) {
          setErrorMessage(null);
          reset();
          setIsSubmitted(true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Reset password link has been sent to your email.",
          });
          window.location = "/login";
        } else {
          setError((prev) => ({
            ...prev,
            email: message || "An error occurred. Please try again.",
          }));
        }
      })
      .catch((error) => {
        setError((prev) => ({
          ...prev,
          email: error.message || "An unexpected error occurred. Please try again.",
        }));
      });
  };

  useEffect(() => {
    if (error.email) {
      setError((prev) => ({ ...prev, email: "" })); // Clear email error
    }
  }, [email]);


  useEffect(() => {
    return () => {
      setIsSubmitted(false);
    };
  }, []);

  return (
    <SingleBoxLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={logo} sx={{ width: 120 }} alt="logo" />
        </Box>
        <Divider sx={{ mt: 2, mb: 4 }} />
        {isSubmitted && (
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem"
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              color={"#444"}
              sx={{ my: 2, display: "flex", alignItems: "center" }}
            >
              <CheckCircleIcon color="success" sx={{ mr: 1 }} /> Reset password
              link has been sent to your email
            </Typography>
          </Box>
        )}
        {isSubmitted === false && (
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem"
              }}
            >
              Forgot Password
            </Typography>
            <Typography color={"#444"} sx={{ mb: 3 }}>
              Input your email account to reset password
            </Typography>
            <Stack spacing={2} direction={"column"}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (error.email) {
                    setError((prev) => ({ ...prev, email: "" }));
                  }
                }}
                error={!!error.email}
                helperText={error.email}
              />
              <Button variant="contained" type="submit">
                Reset Password
              </Button>
            </Stack>
          </Box>
        )}
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Box>
          <Typography textAlign={"center"}>
            Back to <Link to="/login" style={{ textDecoration: "none" }}>Login</Link>
          </Typography>
        </Box>
      </form>
    </SingleBoxLayout>
  );
}

export default ForgotPassword;