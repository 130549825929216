import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { decimalTwoFormat } from "../../utils/formats.js";
import { depositListState } from "../../states/deposit-list.js";
import ChipSuccess from "../commons/ChipSuccess.jsx";
import ChipFail from "../commons/ChipFail.jsx";
import ChipPending from "../commons/ChipPending.jsx";
import EnhancedTableHead from "../commons/EnhancedTableHead.jsx";
import _ from "lodash";
import DepositDialog from "./DepositDialog.jsx";
import moment from "moment-timezone";

function HistoryDepositTable({ data }) {

  const [headCells] = useState([
    { id: "time", label: "Create Date", numeric: false, disablePadding: true, align: "center", minWidth: 200 },
    { id: "m_refno", label: "Transaction Id", numeric: false, disablePadding: false, align: "center", minWidth: 50 },
    { id: "money", label: "Amount", minWidth: 50, numeric: true, align: "center", disablePadding: false },
    { id: "awf", label: "Amount Deduct Fee", numeric: true, align: "center", disablePadding: false },
    { id: "fee", label: "Fee (%)", minWidth: 50, numeric: true, align: "center", disablePadding: false },
    { id: "fee_amount", label: "Fee with Amount", minWidth: 50, numeric: true, align: "center", disablePadding: false },
    { id: "vat", label: "Vat 7% From Fee", minWidth: 50, numeric: true, align: "center", disablePadding: false },
    { id: "status", label: "Status", minWidth: 50, numeric: false, align: "center", disablePadding: false }
  ]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedData, setSortedData] = useState(data);
  const [openDepositDialog, setOpenDepositDialog] = useRecoilState(depositListState.openDepositDialog);
  const [currentPageHistoryList, setCurrentPageHistoryList] = useRecoilState(depositListState.currentPageHistoryList);
  const historyDepositList = useRecoilValue(depositListState.historyDepositList);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setSortedData(historyDepositList);
  }, [historyDepositList]);

  const handleClickOpen = () => {
    setOpenDepositDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedList = _.orderBy(
      historyDepositList,
      [row => (property === "money" ? row.amount : row[property])],
      [isAsc ? "asc" : "desc"]
    );

    setSortedData(sortedList);
  };

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.ceil(sortedData.length / rowsPerPage); // Ensure total pages are calculated correctly
    if (newPage >= totalPages) {
      setCurrentPageHistoryList(totalPages - 1); // If page is beyond available pages, go to the last page
    } else {
      setCurrentPageHistoryList(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPageHistoryList(0); // Reset to first page whenever rows per page changes
  };

  const currentPageData = sortedData.slice(
    currentPageHistoryList * rowsPerPage,
    currentPageHistoryList * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={historyDepositList && historyDepositList.length}
          />
          <TableBody>
            {currentPageData.length > 0 ? (
              currentPageData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                  {moment(row.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">{row.clientTransactionId}</TableCell>
                  <TableCell align="center">{decimalTwoFormat(row.amount)}</TableCell>
                  <TableCell align="center">{decimalTwoFormat(row.amountWithFee)}</TableCell>
                  <TableCell align="center">{decimalTwoFormat(row.totalFee)}</TableCell>
                  <TableCell align="center">{decimalTwoFormat(row.feeInAmount)}</TableCell>
                  <TableCell align="center">{decimalTwoFormat(row.feeInAmount * 0.07)}</TableCell>
                  <TableCell align="center">
                    {row.status === "successful" ? (
                      <ChipSuccess />
                    ) : row.status === "pending" ? (
                      <ChipPending />
                    ) : (
                      <ChipFail />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={historyDepositList ? historyDepositList.length : 0}
        rowsPerPage={rowsPerPage}
        page={currentPageHistoryList}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DepositDialog />
    </>
  );
}

export default HistoryDepositTable;