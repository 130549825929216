import React, { useState } from "react";
import {
  Paper,
  Stack,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Button
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

import { useRecoilState } from "recoil";
import {
  inputSearchHistorywithdraw,
  withdrawFilterStatusState,
  withdrawFilterStartState,
  withdrawFilterEndState
} from "../../states/withdraw-list";
import DialogCusWithdraw from "./DialogCusWithdraw.jsx";
import CustomerContext from "../../contexts/CustomerContext.jsx";
import dayjs from "dayjs";

function SearchWithdrawals(props) {
  const [inputSearchwithdraw, setInputSearchwithdraw] = useRecoilState(
    inputSearchHistorywithdraw
  );
  const [withdrawFilterStatus, setwithdrawFilterStatus] = useRecoilState(
    withdrawFilterStatusState
  );
  const [withdrawFilterStart, setwithdrawFilterStart] = useRecoilState(
    withdrawFilterStartState
  );
  const [withdrawFilterEnd, setwithdrawFilterEnd] = useRecoilState(
    withdrawFilterEndState
  );

  const handleChangeStartDate = (startValue) => {
    if (startValue && startValue.isAfter(withdrawFilterEnd)) {
      setwithdrawFilterEnd(startValue);
    }
    setwithdrawFilterStart(startValue);
  };

  const handleChangeEndDate = (endValue) => {
    if (endValue && endValue.isBefore(withdrawFilterStart)) {
      setwithdrawFilterStart(endValue);
    }
    setwithdrawFilterEnd(endValue);
  };

  const handleChange = (event) => {
    setwithdrawFilterStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setInputSearchwithdraw(event.target.value);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <React.Fragment>
        <CustomerContext.Provider
          value={{
            openDialog,
            setOpenDialog
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ px: 2 }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={2}>
                  <TextField
                    value={inputSearchwithdraw}
                    onChange={handleSearchChange}
                    fullWidth
                    label="Search by Transaction Id"
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small">Status</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={withdrawFilterStatus}
                      label="Status"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Success</MenuItem>
                      <MenuItem value={2}>Pending</MenuItem>
                      <MenuItem value={3}>Failed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <DesktopDatePicker
                    label="Start"
                    inputFormat="DD/MMM/YYYY HH:mm:ss"
                    value={withdrawFilterStart}
                    onChange={handleChangeStartDate}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <DesktopDatePicker
                    label="End"
                    inputFormat="DD/MMM/YYYY HH:mm:ss"
                    value={withdrawFilterEnd}
                    onChange={handleChangeEndDate}
                    minDate={withdrawFilterStart}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </Grid>

                <Grid item sm={2}>
                  <Box>
                    <Button
                      onClick={props.exportCsv}
                      variant="contained"
                      fullWidth
                      sx={{ height: "100%" }}
                      startIcon={<ImportExportIcon />}
                    >
                      Export
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
          <DialogCusWithdraw />
        </CustomerContext.Provider>
      </React.Fragment >
    </>
  );
}

export default SearchWithdrawals;