import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

// date
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { useRecoilState } from "recoil";

import { depositListState } from "../../states/deposit-list";

function SearchDepositForm(props) {
  const [depositFilterStatus, setDepositFilterStatus] = useRecoilState(
    depositListState.depositFilterStatus
  );
  const [depositFilterStart, setDepositFilterStart] = useRecoilState(
    depositListState.depositFilterStart
  );
  const [depositFilterEnd, setDepositFilterEnd] = useRecoilState(
    depositListState.depositFilterEnd
  );
  const [inputSearchHistoryDeposit, setInputSearchHistoryDeposit] =
    useRecoilState(depositListState.inputSearchHistoryDeposit);

  // Handle filter changes
  const handleChange = (event) => {
    setDepositFilterStatus(event.target.value);
  };

  const handleChangeStartDate = (startValue) => {
    if (startValue) {
      if (startValue.isAfter(depositFilterEnd)) {
        setDepositFilterEnd(startValue.add(1, "second"));
      } else if (startValue.isSame(depositFilterEnd)) {
        setDepositFilterEnd(startValue.add(1, "second"));
      }
      setDepositFilterStart(startValue);
    }
  };

  const handleChangeEndDate = (endValue) => {
    if (endValue) {
      if (endValue.isBefore(depositFilterStart)) {
        setDepositFilterStart(endValue.subtract(1, "second"));
      } else if (endValue.isSame(depositFilterStart)) {
        setDepositFilterStart(endValue.subtract(1, "second"));
      }
      setDepositFilterEnd(endValue);
    }
  };

  const handleSearchChange = (event) => {
    setInputSearchHistoryDeposit(event.target.value);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ px: 2 }}>
          <Grid container spacing={2} justifyContent="space-between">
            {/* Search Input */}
            <Grid item xs={12} sm={2}>
              <TextField
                value={inputSearchHistoryDeposit}
                onChange={handleSearchChange}
                fullWidth
                label="Search by Merchant TX Id"
              />
            </Grid>

            {/* Status Dropdown */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  value={depositFilterStatus}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={5}>All</MenuItem>
                  <MenuItem value={1}>Success</MenuItem>
                  <MenuItem value={2}>Pending</MenuItem>
                  <MenuItem value={3}>Failed</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Start Date Picker */}
            <Grid item xs={12} sm={3}>
              <DesktopDatePicker
                label="Start"
                inputFormat="DD/MMM/YYYY HH:mm:ss"
                value={depositFilterStart}
                onChange={handleChangeStartDate}
                maxDate={dayjs()}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* End Date Picker */}
            <Grid item xs={12} sm={3}>
              <DesktopDatePicker
                label="End"
                inputFormat="DD/MMM/YYYY HH:mm:ss"
                value={depositFilterEnd}
                onChange={handleChangeEndDate}
                minDate={depositFilterStart}
                maxDate={dayjs()}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            {/* Export Button */}
            <Grid item sm={2}>
              <Box>
                <Button
                  onClick={props.exportCsv}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Export
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </>
  );
}

export default SearchDepositForm;
