import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  TextField,
  Paper,
  Box,
  Button
} from "@mui/material";
import { useAPI } from "../services/happypay-api";
import Swal from "sweetalert2";

const Settings = () => {
  const [callbackUrl, setCallbackUrl] = useState("");
  const [callbackWithdrawUrl, setCallbackWithdrawUrl] = useState("");
  const [name, setName] = useState("");
  const [minimumBalance, setMinimumBalance] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({});

  const api = useAPI();

  const handleChange = (setValue, field) => (event) => {
    const value = event.target.value;
    setValue(value);

    setError((prevError) => {
      const updatedError = { ...prevError };
      delete updatedError[field];
      return updatedError;
    });
  };

  const validateUrl = (url) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  };

  const handleSubmit = () => {
    const newError = {};

    if (!callbackUrl && !callbackWithdrawUrl) {
      newError.callbackUrl = "Callback URL should not be empty";
    } else if (callbackUrl && !validateUrl(callbackUrl)) {
      newError.callbackUrl = "Callback URL must be a URL address";
    }

    if (callbackWithdrawUrl && !validateUrl(callbackWithdrawUrl)) {
      newError.callbackWithdrawUrl = "Callback URL must be a URL address";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    api
      .updateMerchant(callbackUrl, callbackWithdrawUrl, minimumBalance, name)
      .then((data) => {
        const { status, message } = data;
        if (status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Callback URLs changed successfully.",
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        const { message, statusCode } = error;

        if (statusCode === 400) {
          const apiError = {};

          if (message.includes("callback must be a URL address")) {
            apiError.callbackUrl = "Callback URL must be a valid URL";
          }

          if (message.includes("callback_withdraw must be a URL address")) {
            apiError.callbackWithdrawUrl = "Callback URL must be a valid URL";
          }

          setError(apiError);
        } else {
          setError({ general: "An unexpected error occurred. Please try again." });
        }
      });
  };

  useEffect(() => {
    api
      .profileMerchant()
      .then((data) => {
        const { setting, statusCode, name } = data;
        if (!statusCode) {
          setCallbackUrl(setting[0].callback || "");
          setCallbackWithdrawUrl(setting[0].callbackWithdraw || "");
          setName(name);
          setMinimumBalance(setting.minimum_balance);
        }
      })
      .catch((error) => {
        const { message, statusCode } = error;
        alert(message);
      });
  }, []);

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Settings
        </Typography>
      </Box>
      <Paper sx={{ p: 3, py: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label="Name" value={name} disabled />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!isEditing}
              label="Callback Deposit URL"
              value={callbackUrl}
              onChange={handleChange(setCallbackUrl, "callbackUrl")}
              error={!!error.callbackUrl}
              helperText={error.callbackUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!isEditing}
              label="Callback Withdraw URL"
              value={callbackWithdrawUrl}
              onChange={handleChange(setCallbackWithdrawUrl, "callbackWithdrawUrl")}
              error={!!error.callbackWithdrawUrl}
              helperText={error.callbackWithdrawUrl}
            />
          </Grid>
          <Grid item xs={12} align="right">
            {!isEditing && (
              <Button
                variant="contained"
                sx={{ color: "white" }}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Change
              </Button>
            )}
            {isEditing && (
              <>
                <Button
                  variant="contained"
                  color="close"
                  sx={{ color: "white", mr: 2 }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default Settings;