import { Box, Button, CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useAPI } from "../services/happypay-api";
import * as XLSX from "xlsx";
import { depositListState } from "../states/deposit-list";
import dayjs from "dayjs";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import PendingDepositTable from "../components/deposit/PendingDepositTable";
import HistoryDepositTable from "../components/deposit/HistoryDepositTable";
import SearchInPendingDepositForm from "../components/deposit/SearchInPendingDepositForm";
import SearchDepositForm from "../components/deposit/SearchDepositForm";
import DepositStat from "../components/deposit/DepositStat";
import { authState } from "../states/auth";
import moment from "moment";
import DialogDeposits from "../components/deposit/QrcodeDialog";
import { decimalTwoFormat } from "../utils/formats";
let interval;

function DepositPage() {
  const api = useAPI();
  const profile = useRecoilValue(authState.profile);

  const depositFilterStatus = useRecoilValue(depositListState.depositFilterStatus);
  const depositFilterStart = useRecoilValue(depositListState.depositFilterStart);
  const depositFilterEnd = useRecoilValue(depositListState.depositFilterEnd);

  const [, setPendingDepositList] = useRecoilState(depositListState.pendingDepositList);
  const [historyDepositList, setHistoryDepositList] = useRecoilState(depositListState.historyDepositList);

  const inputSearchHistoryDeposit = useRecoilValue(depositListState.inputSearchHistoryDeposit);
  const inputSearchPendingDeposit = useRecoilValue(depositListState.inputSearchPendingDeposit);
  const inputAmountDeposit = useRecoilValue(depositListState.inputAmountDeposit);

  // pagination ad
  const [currentPageHistoryList, setCurrentPageHistoryList] = useRecoilState(depositListState.currentPageHistoryList);
  const currentPagePendingList = useRecoilValue(depositListState.currentPagePendingList);

  // total balance
  const [, setDepositBalance] = useRecoilState(depositListState.depositBalance);
  const [, setTotalDeposit] = useRecoilState(depositListState.totalDeposit);
  const [, setTodayDeposit] = useRecoilState(depositListState.todayDeposit);
  const [, setTotalPendingDeposit] = useRecoilState(
    depositListState.totalPendingDeposit
  );

  const [qrBase64, setQrCodeDeposit] = useRecoilState(depositListState.qrCodeDeposit)
  const [, setRefNoDeposit] = useRecoilState(depositListState.refNoDeposit)
  const [clientRefno, setMerchantRefNo] = useRecoilState(depositListState.merchantRefNo)
  const [amountGenQr, setAmountGenqr] = useRecoilState(depositListState.amountGenQr)
  const [statusQr, setStatusQr] = useRecoilState(depositListState.statusQr)

  const [openQrcodeDialog, setOpenQrcodeDialog] = useRecoilState(
    depositListState.openQrcodeDialog
  );
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenQrcodeDialog(!openQrcodeDialog);
    if (qrBase64 !== '') {
      clearInterval(interval)
    }
  };

  useEffect(() => {
    if (qrBase64 !== '') {
      interval = setInterval(() => {

        api.depositStatus(clientRefno)
          .then((data) => {
            if (data.qrStatus === "successful") {
              //todo stop
              setStatusQr(true)
              clearInterval(interval)
            }
          })
          .catch(console.error);
      }, 5000);
    }


    return () => clearInterval(interval);
  }, [qrBase64]);


  useEffect(() => {
    api
      .balanceMerchant(localStorage.getItem("token"))
      .then((data) => {
        setTotalDeposit(data?.totalDeposit);
        setTodayDeposit(data?.totalDepositToday);
        setTotalPendingDeposit(data?.totalDepositPending);
        setDepositBalance(data.balance);
      })
      .catch((error) => { });
  }, [api]);

  // useEffect(() => {
  //   api
  //     .pendingDepositList(
  //       inputSearchPendingDeposit,
  //       depositFilterStart && dayjs(depositFilterStart).format("YYYY-MM-DD"),
  //       depositFilterEnd && dayjs(depositFilterEnd).format("YYYY-MM-DD"),
  //       currentPagePendingList + 1
  //     )
  //     .then((data) => {
  //       setPendingDepositList(data);
  //     })
  //     .catch((error) => {
  //       const { message, statusCode } = error;
  //     });
  // }, [
  //   profile,
  //   inputSearchPendingDeposit,
  //   depositFilterStatus,
  //   depositFilterStart,
  //   depositFilterEnd,
  //   currentPagePendingList
  // ]);

  const loadHistoryDeposits = async () => {
    setLoading(true);
    const statusMap =
      depositFilterStatus === 5
        ? "successful,pending,failed"
        : depositFilterStatus === 1
          ? "successful"
          : depositFilterStatus === 2
            ? "pending"
            : "failed";

    try {
      const data = await api.historyDepositList(
        inputSearchHistoryDeposit,
        statusMap,
        depositFilterStart && dayjs(depositFilterStart).format("YYYY-MM-DD HH:mm:ss"),
        depositFilterEnd && dayjs(depositFilterEnd).format("YYYY-MM-DD HH:mm:ss"),
        currentPageHistoryList + 1
      );
      setHistoryDepositList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentPageHistoryList(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHistoryDeposits();
  }, [profile, inputSearchHistoryDeposit, depositFilterStatus, depositFilterStart, depositFilterEnd]);

  // const exportCsv = () => {
  //   api
  //     .getExportDepositURL(depositFilterStart, depositFilterEnd)
  //     .then((data) => {
  //       window.open(data.url, "_blank");
  //     })
  //     .catch(console.error);
  // };

  const genQr = async () => {
    setLoading(true);
    try {
      const data = await api.genDepositQRCode(inputAmountDeposit);
      setQrCodeDeposit(data.qrBase64);
      setRefNoDeposit(data.refno);
      setMerchantRefNo(data.merchantTxId);
      setAmountGenqr(inputAmountDeposit);

      const historyData = await api.historyDepositList(
        inputSearchHistoryDeposit,
        depositFilterStatus === 5
          ? "successful,pending,failed"
          : depositFilterStatus === 1
            ? "successful"
            : depositFilterStatus === 2
              ? "pending"
              : "failed",
        depositFilterStart && dayjs(depositFilterStart).format("YYYY-MM-DD HH:mm:ss"),
        depositFilterEnd && dayjs(depositFilterEnd).format("YYYY-MM-DD HH:mm:ss"),
        currentPageHistoryList + 1
      );
      setHistoryDepositList(historyData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    if (historyDepositList.length === 0) {
      alert("No data available to export");
      return;
    }

    const data = historyDepositList.map((e) => ({
      "Create Date": moment(e.dateCreated).format("DD/MMM/YYYY-HH:mm:ss"),
      "Transaction Id": e.clientTransactionId || "N/A",
      "Amount": e.amount ? parseFloat(decimalTwoFormat(e.amount)) : 0.0,
      "Amount Deduct Fee": e.amountWithFee ? parseFloat(decimalTwoFormat(e.amountWithFee)) : 0.0,
      "Fee (%)": e.totalFee ? parseFloat(decimalTwoFormat(e.totalFee)) : 0.0,
      "Fee with Amount": e.feeInAmount ? parseFloat(decimalTwoFormat(e.feeInAmount)) : 0.0,
      "Vat 7% From Fee": e.feeInAmount
        ? parseFloat(decimalTwoFormat(e.feeInAmount * 0.07))
        : 0.0,
      "Status": e.status || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const header = XLSX.utils.encode_col(col) + "1";
      const headerValue = worksheet[header]?.v;

      if (
        ["Amount", "Amount Deduct Fee", "Fee (%)", "Fee with Amount", "Vat 7% From Fee"].includes(
          headerValue
        )
      ) {
        for (let row = 2; row <= range.e.r + 1; row++) {
          const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
          if (worksheet[cell]) {
            worksheet[cell].t = "n";
            worksheet[cell].z = "#,##0.00";
          }
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Deposits");

    XLSX.writeFile(
      workbook,
      `Report Deposits ${localStorage.getItem("name")} ${dayjs(depositFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(depositFilterEnd).format("DD-MM-YYYY")}.xlsx`
    );
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h4" component="h2">
              Deposits
            </Typography>
          </Grid>
          <Grid display={'flex'}>
            {/* <Grid item marginRight={'10px'}>
              <Box>
                <Button
                  onClick={exportCsv}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Export
                </Button>
              </Box>
            </Grid> */}
            <Grid item>
              <Box>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Generate QRCODE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <DepositStat />

      {/* ปิดDeposit Pending */}
      {/* <Box>
        <Paper>
          <Box sx={{ p: 3 }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography fontSize={"1.3rem"}>Deposit Pending</Typography>
              </Grid>
              <Grid item>
                <SearchInPendingDepositForm />
              </Grid>
            </Grid>
          </Box>
          <PendingDepositTable />
        </Paper>
      </Box> */}
      <Box>
        <Paper>
          <Box sx={{ p: 3 }}>
            <Typography fontSize={"1.3rem"}>Deposits History</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <SearchDepositForm exportCsv={exportToExcel} />
          </Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <HistoryDepositTable data={historyDepositList} currentdata={currentPageHistoryList} />
          )}
        </Paper>
      </Box>
      <DialogDeposits genQr={genQr} handleClickOpen={handleClickOpen} />
    </Stack>
  );
}

export default DepositPage;