import React, { useState } from "react";
import {
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  Paper,
  Box,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useAPI } from "../services/happypay-api";

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [error, setError] = useState({});
  const api = useAPI();

  const handleChange = (setValue, field) => (event) => {
    const value = event.target.value;
    setValue(value);

    setError((prevError) => {
      const updatedError = { ...prevError };
      delete updatedError[field];
      return updatedError;
    });
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleToggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword((prev) => !prev);
  };

  const validateForm = () => {
    const newError = {};
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).+$/;

    if (!newPassword) {
      if (confirmNewPassword) {
        newError.newPassword = "Please fill in the Password field first.";
      } else {
        newError.newPassword = "Password should not be empty.";
      }
    } else if (newPassword.length < 8) {
      newError.newPassword = "Password must be at least 8 characters long.";
    } else if (!passwordRegex.test(newPassword)) {
      newError.newPassword =
        "Password must contain at least one uppercase letter and one number.";
    }

    if (newPassword.length >= 8 && passwordRegex.test(newPassword)) {
      if (!confirmNewPassword) {
        newError.confirmNewPassword = "Confirm Password should not be empty.";
      } else if (newPassword !== confirmNewPassword) {
        newError.confirmNewPassword = "Passwords do not match.";
      }
    }

    setError(newError);

    return Object.keys(newError).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      api
        .changePassword(newPassword, confirmNewPassword)
        .then(async (data) => {
          const { message } = data;
          if (message === "Password changed successfully") {
            await Swal.fire({
              icon: "success",
              title: "Success",
              text: message || "Password changed successfully.",
            });

            // After success, log out and redirect
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location = "/";
          }
        })
        .catch((error) => {
          const { message } = error;
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message || "An error occurred. Please try again.",
          });
        });
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Change Password
        </Typography>
      </Box>
      <Paper sx={{ p: 3, py: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleChange(setNewPassword, "newPassword")}
                error={!!error.newPassword}
                helperText={error.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleNewPasswordVisibility} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Confirm New Password"
                type={showConfirmNewPassword ? "text" : "password"}
                value={confirmNewPassword}
                onChange={handleChange(setConfirmNewPassword, "confirmNewPassword")}
                error={!!error.confirmNewPassword}
                helperText={error.confirmNewPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleConfirmNewPasswordVisibility} edge="end">
                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display={"flex"} justifyContent={"end"}>
                <Button variant="contained" onClick={onSubmit}>
                  Save
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default ChangePasswordPage;