import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { tableCustomerList } from "../../states/customer-list";

function CustomerItem({ row }) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  return (
    <TableRow>
      <TableBodyCell>
        {moment(row.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}
      </TableBodyCell>
      <TableBodyCell>{row.refCustomerId}</TableBodyCell>
      <TableBodyCell>{row.name}</TableBodyCell>
      <TableBodyCell>{row.bankName}</TableBodyCell>
      <TableBodyCell>{row.bookBankNumber}</TableBodyCell>
      <TableBodyCell>{row.bookBankName}</TableBodyCell>
      <TableBodyCell>{row.bookBankBranch}</TableBodyCell>
      {/* <TableBodyCell>{row.customerBranchName}</TableBodyCell> */}
    </TableRow>
  );
}

export default function TableCustomer() {
  const customerListState = useRecoilValue(tableCustomerList.customerList);

  return (
    <Paper sx={{ overflowX: "auto", }}>
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography fontSize={"1.5rem"}>Customers Detail</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Table>
          <TableHead sx={{ fontWeight: "bold", backgroundColor: "#eee" }}>
            <TableRow>
              <TableHeadCell>Create Date</TableHeadCell>
              <TableHeadCell>Ref Customer Id</TableHeadCell>
              <TableHeadCell>Customer Name</TableHeadCell>
              {/* <TableHeadCell>Last Name</TableHeadCell> */}
              <TableHeadCell>Bank Name</TableHeadCell>
              <TableHeadCell>Account Number</TableHeadCell>
              <TableHeadCell>Account Name</TableHeadCell>
              <TableHeadCell>Branch Name</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerListState &&
              customerListState.map((row) => {
                return <CustomerItem row={row} />;
              })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
