import { atom } from "recoil";
import moment from 'moment-timezone';

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const inputSearchHistorywithdraw = atom({
  key: "withdraw-list.inputSearchwithdraw",
  default: ""
});

export const withdrawFilterStatusState = atom({
  key: "withdraw-list.filter-status",
  default: 0
});

export const withdrawFilterStartState = atom({
  key: "withdraw-list.filter-start",
  default: moment().startOf('month').tz(userTimeZone)
});

export const withdrawFilterEndState = atom({
  key: "withdraw-list.filter-end",
  default: moment().endOf('day').tz(userTimeZone)
});

export const withdrawListState = atom({
  key: "withdraw-list.list",
  default: []
});

export const withdrawSumState = atom({
  key: "withdraw-list.sum",
  default: 0
});

export const withdrawSumFeeState = atom({
  key: "withdraw-list.sum_fee",
  default: 0
});

export const openDialogState = atom({
  key: "withdraw-list.openDialog",
  default: false
});
