import React, { useEffect, useRef, useState } from "react";
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Box,
    Paper,
    Button,
    InputLabel,
    OutlinedInput,
    FormControl,
    Alert,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

//formats
import { decimalTwoFormat } from "../../utils/formats.js";
import { useRecoilState } from "recoil";
import { depositListState } from "../../states/deposit-list.js";
import { NumericFormat } from "react-number-format";

import { CircularProgress } from "@mui/material";


function DepositDialog(props) {
    const MIN_DEPOSIT_AMOUNT = 0;
    const MAX_DEPOSIT_AMOUNT = 1999999;

    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const timer = useRef(undefined);

    const [openQrcodeDialog, setOpenQrcodeDialog] = useRecoilState(
        depositListState.openQrcodeDialog
    );

    const [inputAmountDeposit, setInputAmountDeposit] = useRecoilState(
        depositListState.inputAmountDeposit
    );
    const [qrcodeDeposit, setQrCodeDeposit] = useRecoilState(depositListState.qrCodeDeposit)
    const [refNodeposit, setRefNoDeposit] = useRecoilState(depositListState.refNoDeposit)
    const [merchantRefNo, setMerchantRefNo] = useRecoilState(depositListState.merchantRefNo)
    const [statusQr, setStatusQr] = useRecoilState(depositListState.statusQr)
    const [amountGenQr,] = useRecoilState(depositListState.amountGenQr)

    const handleClose = () => {
        props.handleClickOpen()
        setQrCodeDeposit("")
        setRefNoDeposit("")
        setMerchantRefNo("")
        setInputAmountDeposit("")
        // setOpenQrcodeDialog(false);
        setStatusQr(false)
        setIsSubmitted(false);
        setLoading(false);
    };

    const handleChange = (setValue) => (event) => {
        let value = event.target.value;

        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart && decimalPart.length > 2) {
                value = `${integerPart}.${decimalPart.slice(0, 2)}`;
            }
        }

        const numericValue = parseFloat(value);
        const clampedValue = Math.max(MIN_DEPOSIT_AMOUNT, Math.min(MAX_DEPOSIT_AMOUNT, numericValue));

        setValue(clampedValue);
    };

    const onSubmit = () => {
        if (inputAmountDeposit <= MAX_DEPOSIT_AMOUNT && inputAmountDeposit > MIN_DEPOSIT_AMOUNT) {
            setLoading(true);
            props.genQr();
            setTimeout(() => {
                setIsSubmitted(true);
                setLoading(false);
            }, 2000);
            return true;
        } else {
            alert(`Please enter an amount between ${MIN_DEPOSIT_AMOUNT + 1} and ${MAX_DEPOSIT_AMOUNT}, and not 0.`);
            return false;
        }
    };

    const handleButtonClick = () => {
        if (!loading) {
            onSubmit();
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    //mock data
    // let Refno = "00000126";
    // let time = "Oct 17 ,2022, 2:23 AM";
    // let status = "Success";
    // let type = "Deposits";
    // let merchant = "qrs";
    // let customer = "-";
    // let amount = 20000;
    // let fee = 600;
    // let vat = 42;
    // let amountNet = 19358;
    // let balance = 19358;
    // let remark = "-";

    return (
        <div>
            <Dialog open={openQrcodeDialog} onClose={handleClose}>
                <Box maxWidth={500}>
                    <Paper sx={{ p: 2 }}>
                        <DialogTitle textAlign="left">Generate QR CODE for deposit</DialogTitle>
                        <DialogContent>
                            {!loading && !qrcodeDeposit && (
                                <Grid container>
                                    <Grid
                                        item
                                        xs={3}
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography>Amount</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <FormControl variant="outlined" size="small" fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount">
                                                Amount
                                            </InputLabel>
                                            <NumericFormat
                                                id="outlined-adornment-amount"
                                                customInput={OutlinedInput}
                                                label="Amount"
                                                thousandSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                value={inputAmountDeposit}
                                                onValueChange={(values) => {
                                                    const { floatValue } = values;
                                                    setInputAmountDeposit(floatValue || "");
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                        display="flex"
                                        padding="5px"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Button
                                            disabled={
                                                loading ||
                                                !inputAmountDeposit ||
                                                inputAmountDeposit <= 0 ||
                                                inputAmountDeposit > MAX_DEPOSIT_AMOUNT
                                            }
                                            variant="contained"
                                            onClick={handleButtonClick}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        mt={2}
                                    >
                                        <Typography variant="body2" color="textSecondary">
                                            Deposit Min: 1 | Max: 1,999,999
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            {loading && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: "100px" }}
                                >
                                    <CircularProgress />
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Generating QR Code...
                                    </Typography>
                                </Grid>
                            )}

                            {!loading && qrcodeDeposit && (
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            src={`data:image/jpeg;base64,${qrcodeDeposit}`}
                                            alt="Qr Code"
                                        />
                                    </Grid>

                                    {statusQr && (
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Alert
                                                icon={<CheckIcon fontSize="inherit" />}
                                                severity="success"
                                            >
                                                Transaction is PAID
                                            </Alert>
                                        </Grid>
                                    )}

                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            Amount
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            {decimalTwoFormat(amountGenQr)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            Ref No.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            {refNodeposit}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            Tx Merchant.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            fontSize={14}
                                            fontWeight="bold"
                                            color="#79919D"
                                        >
                                            {merchantRefNo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </Paper>
                </Box>
            </Dialog>
        </div>
    );
}

export default DepositDialog;