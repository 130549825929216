import { Box, Grid } from "@mui/material";
import CardAmount from "../cards/Amount";
import { useRecoilValue } from "recoil";
import { depositListState } from "../../states/deposit-list";
import { decimalTwoFormat } from "../../utils/formats";

export default function DepositStat() {
  const depositBalance = useRecoilValue(depositListState.depositBalance);
  const totalDeposit = useRecoilValue(depositListState.totalDeposit);
  const todayDeposit = useRecoilValue(depositListState.todayDeposit);
  const totalPendingDeposit = useRecoilValue(depositListState.totalPendingDeposit);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Deposit Balance"}
            amount={depositBalance && decimalTwoFormat(depositBalance)
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Total Deposit"}
            amount={totalDeposit && decimalTwoFormat(totalDeposit)
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Today Deposit"}
            amount={todayDeposit && decimalTwoFormat(todayDeposit)
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Pending Deposit"}
            amount={totalPendingDeposit && decimalTwoFormat(totalPendingDeposit)
            }
          />
        </Grid>
      </Grid>
    </Box >
  );
}
