import React, { useRef, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  InputAdornment,
  IconButton,
  Divider,
  TextField,
  Button,
  Link as MLink,
  Alert
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAPI } from "../services/happypay-api";

// theme
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import { loginState } from "../states/login";
import logo from "../assets/logo/logo.svg";
import SingleBoxLayout from "../layout/SingleBoxLayout";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function Login() {
  const api = useAPI();
  const [, setIsLogin] = useRecoilState(authState.isLogin);
  const [alertMessage, setAlertMessage] = useRecoilState(
    loginState.alertMessage
  );
  const { setValue, watch, getValues, handleSubmit, reset, formState, register } = useForm({
    defaultValues: {
      username: "",
      password: ""
    },
    mode: "onBlur", // Trigger validation on blur
    criteriaMode: "all" // Show all errors for each field
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state
  const passwordRef = useRef();

  const requestProfile = () => {
    api
      .profileMerchant(localStorage.getItem("token"))
      .then((data) => {
        const { name, statusCode } = data;
        if (!statusCode) {
          localStorage.setItem("name", name);
          setIsLogin(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (values) => {
    setAlertMessage(null);
    setLoading(true); // Start loading when the form is submitted

    api
      .loginMerchant(values.username, values.password)
      .then((data) => {
        const { accessToken } = data;
        reset();
        localStorage.setItem("token", accessToken);
        requestProfile();
      })
      .catch((error) => {
        console.log('error:', error);
        const { message } = error;

        if (Array.isArray(message)) {
          message.forEach((msg) => {
            Swal.fire({
              icon: "warning",
              text: msg,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message || "An error occurred. Please try again.",
          });
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading when the request is finished
      });
  };

  const handleClickShowPassword = () => {
    const catchPassword = getValues("password");
    setShowPassword(!showPassword);
    setValue("password", "");
    passwordRef.current.focus();
    setValue("password", catchPassword);
  };

  const handleChange = (field) => {
    return (event) => {
      setValue(field, event.target.value);
    };
  };

  return (
    <SingleBoxLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={logo} sx={{ width: 120 }} alt="logo" />
          </Box>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem",
                mb: 2,
                textAlign: "center"
              }}
            >
              Merchant Login
            </Typography>
            {alertMessage && !loading && ( // Only show the alert message if there's one and loading is not true
              <Box sx={{ mb: 3 }}>
                <Alert severity="error">{alertMessage}</Alert>
              </Box>
            )}
            <Stack spacing={2} direction={"column"}>
              <TextField
                fullWidth
                label="Username"
                value={watch("username")}
                onChange={handleChange("username")}
                error={!!formState.errors.username} // Highlight if error
                helperText={formState.errors.username?.message} // Display error message
                {...register("username", {
                  required: "Username is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Username must be a valid email"
                  }
                })}
              />
              <TextField
                fullWidth
                label="Password"
                inputRef={passwordRef}
                type={showPassword ? "text" : "password"}
                value={watch("password")}
                onChange={handleChange("password")}
                error={!!formState.errors.password} // Highlight if error
                helperText={formState.errors.password?.message} // Display error message
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                {...register("password", {
                  required: "Password is required"
                })}
              />
              <Button variant="contained" type="submit" disabled={loading}>
                {loading ? "Logging in..." : "Log In"} {/* Change button text when loading */}
              </Button>
            </Stack>
          </Box>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Box>
            <Typography textAlign={"center"}>
              <Link to="/forgot-password" style={{ textDecoration: 'none' }}>Reset password</Link>
            </Typography>
          </Box>
        </Box>
      </form>
    </SingleBoxLayout>
  );
}

export default Login;
