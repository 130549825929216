import { Box, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { withdrawState } from "../../states/withdraw";
import CardAmount from "../cards/Amount";
import { decimalTwoFormat } from "../../utils/formats";

export default function WithdrawBalanceStat() {
  const totalWithdraw = useRecoilValue(withdrawState.totalWithdraw);
  const pendingWithdraw = useRecoilValue(withdrawState.pendingWithdraw);
  const withdrawAvailable = useRecoilValue(withdrawState.withdrawAvailable);
  const pendingTopup = useRecoilValue(withdrawState.pendingTopup);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Withdraws Available"}
            amount={
              withdrawAvailable &&
              decimalTwoFormat(withdrawAvailable)
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Total withdraws"}
            amount={
              totalWithdraw &&
              decimalTwoFormat(totalWithdraw)
            }
          />
        </Grid>
        {/* <Grid item xs={12} md={4} sm={6}>
        <CardAmount
          label={"Pending Withdraw"}
          amount={pendingWithdraw && pendingWithdraw.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      </Grid> */}
        {/* <Grid item xs={12} md={4} sm={6}>
        <CardAmount
          label={"Pending Topup"}
          amount={pendingTopup && pendingTopup.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      </Grid> */}
        {/* <Grid item xs={12} md={4} sm={6}>
        <CardAmount
          label={"Pending Settle"}
          amount={totalBalance && totalBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      </Grid> */}
      </Grid >
    </Box>
  );
}
